.fc-event {
  border-radius: 2px !important;
}

.fc-license-message {
  display: none;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  height: 100%;
}
